import styled from 'styled-components';
import { colors, breakpoints } from 'variables';

export const CardWrapper = styled.article`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-end;
   margin: 30px 15px 0;
   &:first-of-type {
      margin-left: 0;
   }
   &:last-of-type {
      margin-right: 0;
   }

   ${breakpoints.lg`
      margin: 0 15px;
      margin-top: 0;
   `};
`;

export const CardContent = styled.div`
   padding: 20px 25px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   border: 1px solid ${colors.lightCyanMap.get(100)};
   box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
   max-width: 350px;

   &.featured {
      border: 1px solid ${colors.brand};
   }
   ${breakpoints.md`
      padding: 40px 30px 20px;
      margin-bottom: 20px;
      &.featured {
         margin-top: 50px;
         margin-bottom: 0px;
      }
   `}
`;

export const CardName = styled.h3`
   color: ${colors.brand};
   font-weight: 400;
   font-size: 2.4rem;

   ${breakpoints.lg`font-size: 3rem`};
`;

export const CardPrice = styled.h3`
   margin-top: 10px;
   small {
      color: #868da5;
      font-size: 1.4rem;
   }
`;

export const CardDescription = styled.p`
   margin: 15px 0;
   font-size: 1.4rem;
   line-height: 2.1rem;
   ${({ isPackageInclude }) =>
      isPackageInclude ? null : 'margin-bottom: 20px'};

   ${breakpoints.md`
      margin: 25px 0 0;
      ${({ isPackageInclude }) =>
         isPackageInclude ? null : 'margin-bottom: 20px'};
   `};
`;

export const List = styled.ul`
   ${breakpoints.md`margin-bottom: 20px`};
`;

export const ListItem = styled.li`
   font-size: 1.4rem;
   line-height: 2rem;
   padding: 2px 0;
   color: ${colors.black};
`;

export const ItemMarker = styled.img`
   margin-right: 15px;
`;

export const PackageInclude = styled.p`
   img {
      margin-right: 15px;
   }
   display: flex;
   justify-content: space-evenly;
   padding: 8px 10px;
   margin: 20px 0 10px;
   color: ${colors.black};
   background-color: ${colors.lightCyanMap.get(500)};
   border-radius: 8px;
   line-height: 22px;
`;

export const ButtonGroup = styled.div`
   margin-top: 30px;
   display: flex;
   flex-direction: column;
   justify-content: center;
`;

export const TestPeriod = styled.p`
   text-align: center;
   ${breakpoints.md`margin: 10px 0 0`};
`;
