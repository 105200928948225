import arrow from 'assets/icons/arrow_left_24.svg';
import listDecoration from 'assets/icons/checkmark_12.svg';
import ArrowIndicator from 'components/shared/ArrowIndicator';
import Button from 'components/shared/Button';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {
   ButtonGroup,
   CardContent,
   CardDescription,
   CardName,
   CardPrice,
   CardWrapper,
   ItemMarker,
   List,
   ListItem,
   PackageInclude,
   TestPeriod,
} from './PackageCard.styled';

const PackageCard = ({ cardInfo }) => {
   const {
      name,
      price,
      isMonthly,
      description,
      features,
      testPeriod,
      packageInclude,
      button,
      featured = false,
   } = cardInfo;

   return (
      <CardWrapper>
         <CardContent className={featured ? 'featured' : ''}>
            <CardName>{name}</CardName>
            <CardPrice>
               <strong>{price}</strong>{' '}
               {isMonthly && <small>/miesięcznie</small>}
            </CardPrice>
            <CardDescription isPackageInclude={packageInclude}>
               {description.map((text, i) => (
                  <Fragment key={i}>{text}</Fragment>
               ))}
            </CardDescription>

            {packageInclude && (
               <PackageInclude>
                  <img src={arrow} alt="" />
                  <small style={{fontSize: "13px"}}>{packageInclude}</small>
               </PackageInclude>
            )}

            {features.length && (
               <List>
                  {features.map((item, i) => (
                     <ListItem key={i}>
                        <ItemMarker src={listDecoration} alt="decoration" />
                        {item}
                     </ListItem>
                  ))}
               </List>
            )}

            <ButtonGroup>
               <Button filled link={button.to} state={button.state}>
                  {button.text}
               </Button>
               <TestPeriod>
                  {testPeriod && <small>{testPeriod}</small>}
               </TestPeriod>
            </ButtonGroup>
         </CardContent>
      </CardWrapper>
   );
};

PackageCard.propTypes = {
   name: PropTypes.string,
   oneTimePayment: PropTypes.bool,
   price: PropTypes.string,
   description: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
   ),
   features: PropTypes.arrayOf(PropTypes.string),
   button: PropTypes.object,
   testPeriod: PropTypes.string,
   packageInclude: PropTypes.string,
};

export default PackageCard;
