import arrow_right from 'assets/icons/arrow_right_24.svg';
import routes from 'data/globals/routes';
import React from 'react';
import PackageCard from './PackageCard';
import {
   MoreInformation,
   PackagesContainer,
   StyledContainer,
   StyledLink,
} from './PackagesSection.styled';

const PackagesSection = ({ packagesLinks }) => {
   const sectionLink = packagesLinks.link;

   const packages = [
      {
         name: 'Standard',
         price: ['od 1000 zł'],
         isMonthly: true,
         description: [
            'Wersja Standard to ',
            <strong>pełny zakres funkcjonalności</strong>,
            ' systemu dedykowany przedsiębiorstwom różnego typu.',
         ],
         features: [
            'Tworzenie formularzy zgłoszeń',
            'Umawianie wizyt',
            'Kalendarz wizyt',
            'Śledzenie statusu wizyty',
         ],
         featured: true,
         testPeriod: '1 miesiąc jest darmowy',
      },
      {
         name: 'Standard Plus',
         price: 'Skontaktuj się',
         description: [
            'Rocket to rozwiązanie uwzględniające ',
            <strong>indywidualne potrzeby klienta.</strong>,
         ],
         packageInclude: 'Zawiera wszystko z wersji Standard',
         features: [
            'Zatwierdzanie wizyt przez lekarzy',
            'Wsparcie sztucznej inteligencji w diagnozowaniu',
            'Prowadzenie spisu pracowników',
         ],
         featured: false,
      },
   ];

   packagesLinks.buttons.forEach((button, i) => (packages[i].button = button));

   const packagesList = packages.map((card, index) => (
      <PackageCard key={index} cardInfo={card} />
   ));

   return (
      <StyledContainer>
         <PackagesContainer>{packagesList}</PackagesContainer>

         <MoreInformation>
            {sectionLink.to.startsWith('/') ? (
               <StyledLink to={`${sectionLink.to}#pakiety`}>
                  {sectionLink.text}
                  <img src={arrow_right} alt="arrow" />
               </StyledLink>
            ) : (
               <StyledLink to={`${routes.pricing}#pakiety`} $rotateArrow={true}>
                  {sectionLink.text}
                  <img src={arrow_right} alt="arrow" />
               </StyledLink>
            )}
         </MoreInformation>
      </StyledContainer>
   );
};

export default PackagesSection;
